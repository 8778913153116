import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  //static targets = ['message', 'inputMessage']

  initialize() {
  }

  connect() {
    let controller = this;
    let chatId = this.data.get("chat-id")

    this.subscription = consumer.subscriptions.create("ChatNotificationChannel", {
      connected() {
        console.log("chatNotificationChannel connected")
      },
      disconnected() {
        console.log("chatNotificationChannel disconnected")
      },
      received(data) {
        console.log("chatNotificationChannel received: ")
        console.log(data)
        //controller.renderPartial(data);
      }
    });
  }

  disconnect() {
    this.subscription.unsubscribe();
  }
}
