//window.addEventListener('load', () => {
up.$compiler('.closebtn', function($element) {
  $element.click(function(e){
    closeNav();
  });
});

up.$compiler('#chat-open-sidenav', function($element) {
  $element.click(function(){
    openNav();
  });
});


function openNav() {
  let max = window.screen.width
  let witdh = "350px"
  if(350 > max) {
    witdh = max + "px"
  }
  document.getElementById("chat-sidenav").style.width = witdh
}

function closeNav() {
  document.getElementById("chat-sidenav").style.width = "0"
}
