import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["visualizer", "player"]
  static values = { chat: Number }
  initialize(){
    this.onCloseModal = this.handleModalClose.bind(this)
}

  connect() {
    this.sendAudio = false 

    up.on('up:modal:close', this.onCloseModal) 

    this.canvas = this.visualizerTarget
    this.canvasCtx = this.canvas.getContext("2d")

    if (navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.')

      const constraints = { audio: true }
      this.chunks = []

      navigator.mediaDevices.getUserMedia(constraints).then(
        stream => {
          this.chunks = []
          this.mediaRecorder = new MediaRecorder(stream)
          this.visualize(stream)
          console.log("MediaRecorder start...............")
          this.mediaRecorder.start()
          console.log(this.mediaRecorder.state)


          this.mediaRecorder.addEventListener('dataavailable', e => {
             this.chunks.push(e.data);
          })

          this.mediaRecorder.addEventListener('stop', e => {
            console.log('Stop')
            console.log('Send audio: ' + this.sendAudio)

            if (this.sendAudio) {
              const blob = new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' });
              this.chunks = [];
              
              let fd = new FormData()
              fd.append('message_audio_form[file]', blob, 'audio.ogg')

              fetch(`/chats/${this.chatValue}/message_audios`, {
                method: "POST", 
                headers: {
                  //'Content-type': 'multipart/form-data',
                  "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
                },
                body: fd
              })
              .then(response => console.log(response.text()))
            } //end if sendAudio 
          }) //end event stop

        },//end stream 
        error => {
          console.log('The following error occured: ' + err)
        }
      )

    } //end if getUserMedia 
  }

  disconnect(){
    console.log("disconect..............................")
    up.off('up:modal:close', this.onCloseModal) 
  }

  handleModalClose(e) {
    if(this.mediaRecorder) {
      console.log("MediaRecorder start...............")
      this.mediaRecorder.stop();
      console.log(this.mediaRecorder.state);
    }
  }

  visualize(stream) {
    if(!this.audioCtx) {
      this.audioCtx = new AudioContext()
    }

    const source = this.audioCtx.createMediaStreamSource(stream)

    this.analyser = this.audioCtx.createAnalyser()
    this.analyser.fftSize = 2048
    this.bufferLength = this.analyser.frequencyBinCount
    this.dataArray = new Uint8Array(this.bufferLength)

    source.connect(this.analyser)
    this.draw()
  }

  send(){
    this.sendAudio = true
    console.log("SEND .......")
    up.modal.close()
  }

  cancel(){
    this.sendAudio = false
    console.log("CANCEL......")
    up.modal.close()
  }

  draw() {
    const WIDTH = this.canvas.width
    const HEIGHT = this.canvas.height

    requestAnimationFrame(this.draw.bind(this))

    this.analyser.getByteTimeDomainData(this.dataArray)

    this.canvasCtx.fillStyle = 'rgb(200, 200, 200)'
    this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT)

    this.canvasCtx.lineWidth = 2;
    this.canvasCtx.strokeStyle = 'rgb(0, 0, 0)'
    this.canvasCtx.beginPath();

    let sliceWidth = WIDTH * 1.0 / this.bufferLength
    let x = 0

    for(let i = 0; i < this.bufferLength; i++) {
      let v = this.dataArray[i] / 128.0;
      let y = v * HEIGHT/2;

      if(i === 0) {
        this.canvasCtx.moveTo(x, y)
      } else {
        this.canvasCtx.lineTo(x, y)
      }
      x += sliceWidth;
    }
    this.canvasCtx.lineTo(this.canvas.width, this.canvas.height/2)
    this.canvasCtx.stroke()
  }
}
