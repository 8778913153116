import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "msgDiv", "more" ]
  static values = { last: String, userId: Number, chatId: Number }

  initialize(){
  }

  connect() {
  }

  disconnect(){
  }

  selectUser(e) {
    window.location.href = `/messages?user_id=${e.target.value}`
  }

  selectChat(e) {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('user_id') ? urlParams.get('user_id') : this.userIdValue
    window.location.href = `/messages?user_id=${userId}&chat_id=${e.target.value}`
  }

  loadMessage(e) {
    const urlParams = new URLSearchParams(window.location.search)
    const userId = urlParams.get('user_id') ? urlParams.get('user_id') : this.userIdValue
    const chatId = urlParams.get('chat_id') ? urlParams.get('chat_id') : this.chatIdValue
    const url = `/messages?user_id=${userId}&chat_id=${chatId}&last=${this.lastValue}`

    fetch(url, {method: 'GET'})
      .then(res => {
        return res.text()
      })
      .then(html => {
        const doc = new DOMParser().parseFromString(html, "text/html") 
        const list = doc.getElementsByClassName("messages")
        if (list[0] === undefined) {
          this.moreTarget.style.display = "none"
        } else {
          const last = list[0].dataset.last
          this.lastValue = last
          this.msgDivTarget.insertAdjacentHTML("afterbegin", list[0].outerHTML)
        }
     })
  }
}
