import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['count', 'bubble']

  initialize() {
  }

  connect() {
    let controller = this;

    this.subscription = consumer.subscriptions.create("ChatResumeChannel", {
      connected() {
        console.log("chat_resume_channel_controller connected")
      },
      disconnected() {
        console.log("chat_resume_channel_controller disconnected")
      },
      received(data) {
        console.log("chat_resume_channel_controller received: ")
        controller.renderPartial(data);
        up.proxy.clear() //elimina chache unpoly:
      }
    })
  }

  disconnect() {
    console.log("chat_resume_channel_controller disconnect ")
    this.subscription.unsubscribe();
  }

  renderPartial(data) {
    this.countTarget.innerHTML = `<strong>${data['unread_count']}</strong>`
    this.countTarget.style.setProperty('display', 'block')
    this.bubbleTarget.style.setProperty('display', 'block')
  }
}
