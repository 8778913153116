import { Controller } from 'stimulus';
import { Loader } from "@googlemaps/js-api-loader"
export default class extends Controller {
  static targets = ['map', 'latitude', 'longitude', 'content']
  connect() {
    if (this._loader == undefined) {
      this._loader = new Loader({
        apiKey: "AIzaSyCC2k6vgIYLjCG3_VbVW9We1lBLpvTgMFc",
        version: "weekly",
        mapId: "9d3a97a4105315bf"
      })
    }
    this.map()
  }

  send() {
    this.latitudeTarget.value = this._marker.getPosition().lat()
    this.longitudeTarget.value = this._marker.getPosition().lng()
  }

  map() {
    if (this._map == undefined) {
      this._loader.load().then(() => {
        navigator.geolocation.getCurrentPosition(position => {
          this._lat = position.coords.latitude
          this._lon = position.coords.longitude

          this._map = new google.maps.Map(this.mapTarget, {
            center: {
              lat: parseFloat(this._lat),
              lng: parseFloat(this._lon)
            },
            zoom: 14,
          })

          this._marker = new google.maps.Marker({
            map: this._map,
            draggable: true,
            anchorPoint: new google.maps.Point(0, 0)
          })

          this._marker.setPosition({
            lat: parseFloat(this._lat),
            lng: parseFloat(this._lon)
          })
          this._marker.setVisible(true)

          this._marker.addListener("dragend", (event) => {
            console.log("maker clci")
            this.address(event.latLng,)
          })

        })
      })
    }
  }

  address(latlng) {
    let geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let infowindow = new google.maps.InfoWindow()
          infowindow.setContent(results[0].formatted_address)
          infowindow.open(this._map, this._marker)
          this.contentTarget.value = results[0].formatted_address
        } else {
          window.alert("No results found")
        }
      } else {
        window.alert("Geocoder failed due to: " + status)
      }
    });
  }
}
