up.compiler('#messages', function(element) {
  scrollFeedToBottom()
  notificationStatus()
})

export function scrollFeedToBottom() {
  let messageFeed = document.getElementById('messageFeed')
  if(messageFeed != null){
    messageFeed.scrollTop = messageFeed.scrollHeight
  }
}


function notificationStatus() {
  let status = document.getElementById("notification-status")
  if(status != null) {
    if (Notification.permission === 'denied') {
      status.innerHTML = 'Notificaciones bloqueadas'
    } else {
      status.innerHTML = "Notificaciones activas: " + Notification.permission
    }
  }
}
