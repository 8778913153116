import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['list']

  initialize() {
  }

  connect() {
    let controller = this;

    this.subscription = consumer.subscriptions.create("ChatListChannel", {
      connected() {
        console.log("chat_list_channel_controller connected")
      },
      disconnected() {
        console.log("chat_list_channel_controller disconnected")
      },
      received(data) {
        console.log("chat_list_channel_controller received: ")
        controller.renderPartial(data);
        up.proxy.clear() //elimina chache unpoly:
      }
    });
  }

  disconnect() {
    console.log("chat_list_channel_controller disconnect ")
    this.subscription.unsubscribe();
  }

  renderPartial(data) {
    const ele = document.getElementById("chat-" + data["id"])
    if (ele) {
       ele.parentNode.removeChild(ele)
    }
    this.listTarget.insertAdjacentHTML('afterbegin', data["body"])
  }

}
