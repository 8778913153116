import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "thanks" ]
  initialize(){
  }

  connect() {
    console.log("connnect ........................")
    console.log(window.location.pathname)
    console.log(window.location.href)

    if(window.location.href.indexOf('thanks') > 0){
      console.log("thanks")
      this.thanksTarget.style.display = "block"
    }
  }

  disconnect(){
    console.log("disconect..............................")
  }
}
