import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['message', 'inputMessage']
  static values = {lastmsg: Number, notMessage: String}

  initialize() {
    this.onOpenModal = this.handleOpenModal.bind(this)
  }

  connect() {
    up.on('up:modal:open', this.onOpenModal) 
    
    let controller = this
    let chatId = this.data.get("chat-id")

    this.subscription = consumer.subscriptions.create({ channel: "ChatChannel", chat_id: chatId }, {
      connected() {
      },
      disconnected() {
      },
      received(data) {
        controller.renderPartial(data)
        controller.lastmsgValue = Date.now() / 1000 //pudo envíar o recibir un mensaje, por lo que se actualiza la fecha del último mensaje
        this.perform('read', {id: data["id"]})
      },
      speak: function(message) {
        return this.perform('speak', { message: message, chat_id: chatId })
      }
    })
  }

  disconnect() {
    up.off('up:modal:open', this.onOpenModal) 
    this.subscription.unsubscribe()
  }

  handleOpenModal(event) {
    if( event.url.includes("message") && !this.canSendMessage() ){
      event.preventDefault()
    }
  }

  send(event) {
    if (this.canSendMessage() && event.key == "Enter") {
      event.preventDefault()
      this.subscription.speak(event.target.value)
      event.target.value = ''
    }
  }

  buttonSend(event) {
    if (this.canSendMessage() && this.inputMessageTarget.value.trim()) {
      event.preventDefault()
      this.subscription.speak(this.inputMessageTarget.value.trim())
      this.inputMessageTarget.value = ''
    }
  }

  canSendMessage() {
    let today = Date.now() / 1000 // en segundos
    if( today - this.lastmsgValue >= 24*3600) {
      alert(this.notMessageValue)
      return false
    } 
    return true
  }

  renderPartial(data) {
    const ele = document.getElementById("message-" + data["id"])
    if (ele != null) {
      let start = data["body"].indexOf("<!--start-->")
      let end = data["body"].lastIndexOf("<!--end-->")
      ele.innerHTML = data["body"].substring(start, end)
    } else {
      this.messageTarget.insertAdjacentHTML("beforeend", data["body"])
      this.messageTarget.scrollTop = this.messageTarget.scrollHeight
    }
  }
}
