import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['paypalButton', 'totalPrice', 'totalMessages', 'quantity', 'alert', 'success']
  static values = {price: Number, packNumber: Number}

  connect() {
    let ctrl = this

    paypal.Buttons({
      onInit: function(data, actions) {
         ctrl.quantityTarget.addEventListener('input', e => {
            if (ctrl.validQuantity(e.target.value)) {
              ctrl.alertTarget.style.display = "none"
              actions.enable();
            } else {
              ctrl.alertTarget.style.display = "block"
              actions.disable();
            }
          })
      },
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{
            //custom_id: "custom_abc",
            description: "Message Pack",
            amount: {
              value: ctrl.round(ctrl.quantityTarget.value * ctrl.priceValue)
            },
          }],
          application_context: {
            brand_name: "Dichat.io",
            shipping_preference: 'NO_SHIPPING'
          }
        });
      },
      onApprove: function(data, actions) {
        console.log("*****************************")
        console.log(data)
        return actions.order.capture().then(detail => {
          console.log("++++++++++++++++++++++++++++++")
          console.log(detail)
          ctrl.successTarget.style.display = "block"
          up.replace('.list-transactions', '/paypal_payments', {
            method: 'POST',
            params: {
                'paypal_payment[order_id]': detail.id,
                'paypal_payment[paypal_id]': detail.purchase_units[0].payments.captures[0].id,
                'paypal_payment[status]': detail.status,
                'paypal_payment[create_time]':  detail.create_time,
                'paypal_payment[amount]': detail.purchase_units[0].amount.value,
                'quantity': ctrl.quantityTarget.value * ctrl.packNumberValue
            }
          })
        });
      }
    }).render(`#${this.paypalButtonTarget.id}`);

  }
  verify(event) {
    if (!this.validQuantity(event.target.value)){
      this.totalPriceTarget.innerHTML= ""
      this.totalMessagesTarget.innerHTML= ""
      return
    }
    event.target.value = Number.parseInt(event.target.value, 10)
    this.totalPriceTarget.innerHTML = this.round(event.target.value * this.priceValue, -2)
    this.totalMessagesTarget.innerHTML = this.round(event.target.value * this.packNumberValue, -2)
  }
  validQuantity(value) {
    let q = Number.parseInt(value, 10)
    return !(Number.isNaN(q) || q < 1)
  }

  round(num){
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
}
