import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = [ "facebookId", "form" ]

  send(event) {
    if (!this.formTarget.checkValidity()) {
      return
    }

    event.preventDefault()
    const facebook_business_id = this.facebookIdTarget.value
    const company_id = this.data.get("company")

    fetch(`/companies/${company_id}.json`, {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        company: {
          facebook_business_id: facebook_business_id
        }
      })
    })
    .then(response => {
      if (response.status == 200){
        window.location.href = window.location.pathname
      }
    })
  }
}
