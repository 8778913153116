up.compiler('#paypal-payment-add-amount', function(element) {

  document.querySelectorAll("input[type=checkbox][name='add-amount']")
    .forEach(radio => radio.addEventListener("input", event => {
      if(event.target.checked && event.target.id == 'instant-balance') {
        document.getElementById("paypal-checkout").style.display = "block"
        document.getElementById("paypal-subscribe-fund").style.display = "none"
      } else {
        document.getElementById("paypal-checkout").style.display = "none"
        document.getElementById("paypal-subscribe-fund").style.display = "block"
      }
    }))
})

