import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['whatsapp', 'plan']
  connect() {
  }

  active() {
    this.whatsappTarget.classList.remove('active')
    this.planTarget.classList.add('active')
  }
}
