import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['buttonSubscribe', 'result', 'quantity', 'quantityInfo']
  static values = {userId: Number, planId: Number, planPrice: Number, paypalPlanId: String, paypalSubscriptionId: String, subscriptionId: Number, currentPrice: Number}

  connect() {
    if( this.hasButtonSubscribeTarget ) {
      paypal.Buttons({
        style: {
          shape: 'pill',
          label: 'subscribe'
        },
        createSubscription: (data, actions) => {
          const quantity = this.hasQuantityTarget ? this.quantityTarget.value : 1 
          const newPrice = quantity * this.planPriceValue
          const dataSubscription = {
                'custom_id': this.userIdValue,
                'plan_id': this.paypalPlanIdValue,
                'quantity': quantity,
                'application_context': {
                  'shipping_preference': 'NO_SHIPPING'
                }
              }
          console.log(`quantity: ${quantity}`)
          console.log(`newPrice: ${newPrice}`)
          console.log(`currentPrice: ${this.currentPriceValue}`)
          console.log(`dataSubscription: ${JSON.stringify(dataSubscription)}`)

          //new subscription
          if( newPrice > this.currentPriceValue ) {
            return actions.subscription.create(dataSubscription)
          } else { //update subscription
            return actions.subscription.revise(this.paypalSubscriptionIdValue, dataSubscription)
          } 
        }, //createSubscription
        onApprove: (data, actions) => {
          const quantity = this.hasQuantityTarget ? this.quantityTarget.value : 1 
          console.log("data...")
          console.log(JSON.stringify(data))
          const cuerpo = JSON.stringify({
              paypal_subscription_form: {
                order_id: data.orderID,
                paypal_plan_id: this.paypalPlanIdValue,
                paypal_subscription_id: data.subscriptionID,
                quantity_plan: quantity
              }
            })

          console.log(`cuerpo: ${cuerpo}`)

          
          fetch('/paypal_subscriptions', {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({
              paypal_subscription_form: {
                order_id: data.orderID,
                paypal_plan_id: this.paypalPlanIdValue,
                paypal_subscription_id: data.subscriptionID,
                quantity_plan: quantity
              }
            })
          })
          .then(response => {
              fetch(response.url)
              .then(res => {
                return res.text() 
              })
              .then(html => { 
                up.extract('.plans', html)
                up.proxy.clear()
              })
          })
        } //onApprove
      }).render(`#${this.buttonSubscribeTarget.id}`);
    }//if
  }//connect

  pay(event) {
    const planPrice = this.planPriceValue
    let quantityValue = Number.parseInt(event.target.value, 10)

    if( event.type == 'focusout'){
      if (Number.isNaN(quantityValue) || quantityValue <= 0){
        quantityValue = 1
      }
      event.target.value = quantityValue
    }
    let price = Number.parseFloat(planPrice, 10)
    let result = quantityValue * price
    this.resultTarget.innerHTML = `USD ${Number.isNaN(result) ? 0 : result}`
  }
}
