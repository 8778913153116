import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['start', 'end']
  connect() {
  }

  allDay(event) {
    this.setDisabled(this.startTarget, event.target.checked)
    this.setDisabled(this.endTarget, event.target.checked)
  }

  setDisabled(target, value) {
    for (var i = 0; i < target.children.length; i++) {
      let element = target.children[i]
      if (element.tagName == "SELECT") {
        element.disabled = value
      }
    }
  }
}
