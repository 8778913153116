import consumer from "./consumer"

const notificationHtml = `&#9776;<span class="badge badge-light" style="color: red;">&#9900;</span>`;
const notificationDarkHtml = `<span class="badge badge-dark" style="color: red;">&#9900;</span>`;

//notifica si ha llegado un nuevo mensaje desde algún chat distinto al abierto
let chatNotification = null;

up.compiler('#chat-sidenav', (_ele) => {
  chatNotification = getChatNotification();
});


/**
 * Obtiene una conexión para saber si ha llegado un nuevo chat
 */
function getChatNotification() {
  if ( chatNotification != null) {
    console.log("ChatNotificationChannel found");
    return chatNotification;
  }

  chatNotification = consumer.subscriptions.create("ChatNotificationChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("ChatNotificationChannel connected");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      console.log("ChatNotificationChannel disconnected");
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      console.log("ChatNotificationChannel received, data: " + JSON.stringify(data));

      const pathname = window.location.pathname;
      //si es el mismo chat abierto, no realiza notificación
      if(!pathname.startsWith('/chats/' + data["chat_id"])) {
        insertNotification();
        insertNotificationChat(data["chat_id"], data["customer_alias"]);
      }
    }
  });

  return chatNotification;
}


function insertNotification() {
  const ele = document.getElementById("chat-open-sidenav")
  if(ele != null) {
    ele.innerHTML = notificationHtml
  }
}

function insertNotificationChat(id, alias) {
  const sidenav = document.getElementById("chat-sidenav")
  const ele = document.getElementById("chat-" + id)

  if(sidenav != null){
    if(ele != null){
      ele.innerHTML = notificationDarkHtml
    }else { //es un chat nuevo
      const newHtml = `
      <a href="/chats/${id}">
        ${alias}
        <span id="chat-${id}">${notificationDarkHtml}</span>
      </a>
      `
      sidenav.insertAdjacentHTML("beforeend", newHtml)
    }
  }
}
