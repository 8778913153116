// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
//require("turbolinks").start()
require("unpoly")
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("jquery")
require("custom/sidenav")
require("custom/chat")
require("custom/payment")
require("@googlemaps/js-api-loader")

import 'controllers'

window.jQuery = window.$ = jQuery; //unpoly lo necesita para reconocer jquery

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

registerServiceWorker();

//sólo cuando entra al chat le pide permisos para notificaciones y se subscribe
up.compiler("#chat-list", (ele) => {
  saveSubscription();
});


/**
 * Registra el serviceWorker
 */
function registerServiceWorker() {
  console.log('ServiceWorker registerServiceWorker.....');
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered.');
    ///////////
    if (registration.installing) {
      console.log('ServiceWorker installing.');
    } else if (registration.waiting) {
      console.log('ServiceWorker installed & waiting.');
    } else if (registration.active) {
      console.log('ServiceWorker active.');
    }
    ///////////
    //Permisos para notificaciones
    /* window.Notification.requestPermission().then(permission => {
       if(permission !== 'granted'){
         throw new Error('No ha dado permisos');
       }
     });*/
  }).catch(registrationError => {
    console.log('ServiceWorker registration failed: ', registrationError);
  });
}


/**
 * Si el serviceWorker esta listo, se subscribe para las push notification
 */
function saveSubscription() {
  console.log("ServiceWorker saveSubscription");
  navigator.serviceWorker.ready.then(registration => {
    console.log("ServiceWorker registration is ready, saveSubscription");
    const applicationServerKey = urlB64ToUint8Array('BOMfQ3B7L4RlcFcMKVRAnaiZM2efa7duUJ2TVd05UtuBZlypoNE5eGkt4xLl9v4lJviI0dGbAkCbQFuE528BoV4')
    const options = { applicationServerKey, userVisibleOnly: true }
    registration.pushManager.subscribe(options).then(function (subscription) {
      saveNotification(subscription);
    });
  });
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


function saveNotification(subscription) {
  return fetch('/notifications.json', {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify({
      notification: subscription
    })
  });
}
