import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "customers", "btnReasign" ]
  initialize(){
  }

  connect() {
    console.log("connnect customer........................")
  }

  disconnect(){
    console.log("disconect..............................")
  }

  select(e){
    console.log(e)
    console.log(this.customersTargets)
    console.log(e.target.value)
    console.log(e.target.checked)

    let result = false
    for (const checkbox of this.customersTargets){
      result = result || checkbox.checked
    }
    console.log("result**************")
    console.log(result)
    if (result) {
      this.btnReasignTarget.style="display: block;"
    } else {
      this.btnReasignTarget.style="display: none;"
    }
  }
}
